module.exports = {

    // Generic
    'generic.add': '新增',
    'generic.cancel': '取消',

    // BlockType
    'components.controls.blocktype.h1': '標題1',
    'components.controls.blocktype.h2': '標題2',
    'components.controls.blocktype.h3': '標題3',
    'components.controls.blocktype.h4': '標題4',
    'components.controls.blocktype.h5': '標題5',
    'components.controls.blocktype.h6': '標題6',
    'components.controls.blocktype.blockquote': '引用',
    'components.controls.blocktype.code': '程式碼',
    'components.controls.blocktype.blocktype': '樣式',
    'components.controls.blocktype.normal': '正文',

    // Color Picker
    'components.controls.colorpicker.colorpicker': '選色器',
    'components.controls.colorpicker.text': '文字',
    'components.controls.colorpicker.background': '背景',

    // Embedded
    'components.controls.embedded.embedded': '內嵌',
    'components.controls.embedded.embeddedlink': '內嵌網頁',
    'components.controls.embedded.enterlink': '輸入網頁地址',

    // Emoji
    'components.controls.emoji.emoji': '表情符號',

    // FontFamily
    'components.controls.fontfamily.fontfamily': '字體',

    // FontSize
    'components.controls.fontsize.fontsize': '字體大小',

    // History
    'components.controls.history.history': '歷史紀錄',
    'components.controls.history.undo': '復原',
    'components.controls.history.redo': '重做',

    // Image
    'components.controls.image.image': '圖片',
    'components.controls.image.fileUpload': '檔案上傳',
    'components.controls.image.byURL': '網址',
    'components.controls.image.dropFileText': '點擊或拖曳檔案上傳',

    // Inline
    'components.controls.inline.bold': '粗體',
    'components.controls.inline.italic': '斜體',
    'components.controls.inline.underline': '底線',
    'components.controls.inline.strikethrough': '刪除線',
    'components.controls.inline.monospace': '等寬字體',
    'components.controls.inline.superscript': '上標',
    'components.controls.inline.subscript': '下標',

    // Link
    'components.controls.link.linkTitle': '超連結',
    'components.controls.link.linkTarget': '輸入連結位址',
    'components.controls.link.linkTargetOption': '在新視窗打開連結',
    'components.controls.link.link': '連結',
    'components.controls.link.unlink': '刪除連結',

    // List
    'components.controls.list.list': '列表',
    'components.controls.list.unordered': '項目符號',
    'components.controls.list.ordered': '編號',
    'components.controls.list.indent': '增加縮排',
    'components.controls.list.outdent': '減少縮排',

    // Remove
    'components.controls.remove.remove': '清除格式',

    // TextAlign
    'components.controls.textalign.textalign': '文字對齊',
    'components.controls.textalign.left': '文字向左對齊',
    'components.controls.textalign.center': '文字置中',
    'components.controls.textalign.right': '文字向右對齊',
    'components.controls.textalign.justify': '兩端對齊',
};