
/**
 * url管理
 */
const Base =  {
    // baseUrl: 'http://127.0.0.1:8000/api/',
    // downloadPPT:'http://127.0.0.1:8000/files/community.pptx',
    // baseName:"community_1",//nginx子目錄名稱
    // imgBaseName:"http://127.0.0.1:3000/community_1"

    baseUrl: 'https://communityBackend.kaiwebsite.name/api/',
    downloadPPT:'https://communityBackend.kaiwebsite.name/files/community.pptx',
    baseName:"",//nginx子目錄名稱
    imgBaseName:"https://community.kaiwebsite.name"
}

export default Base;